import Exec from "../Exec";
import { IDataType } from "src/engine/SourceCenter/Manager";
import Storage from "src/lib/utils/storage/Storage";
import StorageType from "src/lib/utils/storage/StorageType";
import ModuleMgr from "../SourceArtical/ModuleMgr";
import Utils from "src/lib/utils/Utils";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";

/**
 *
 *
 * @class Handle
 */
class Handle extends Exec {
    callback: any;

    propsListener() {
        return ["initData", "dataValue", "initHandle", "type", "mapKey", "mapValueKey", "sync"];
    }

    storages = {};

    getStorage(type: StorageType) {
        let storage = this.storages[type];
        if (!storage) {
            storage = this.storages[type] = new Storage(Number(type));
        }
        return storage;
    }

    syncKey(key: string = "area", sync: Array<StorageType | any> = [], saveValue?: any) {
        for (let item of sync) {
            if (typeof item === "object") {
                key = item.key || key;
                item = item.type;
            }
            const storages = this.getStorage(item);
            if (key && key.indexOf("[") > 0) {
                const list = Utils.parser(/\[(.+?)\]/gim, key, 1);

                if (list.length > 0) {
                    list.map((item) => {
                        const transValue = PropsUtils.getValue(this.itemProps, item);
                        if (transValue !== undefined) key = key.replace(`[${item}]`, transValue);
                    });
                }
            }
            if (saveValue !== undefined) {
                storages.set(key, typeof saveValue === "object" ? JSON.stringify(saveValue) : saveValue);
            } else {
                let value = storages.get(key);
                if (typeof value !== "undefined") {
                    if (typeof value === "string") {
                        if (value.indexOf("{") > -1 || value.indexOf("[") > -1) {
                            value = JSON.parse(value);
                        }
                    }
                    return value;
                }
            }
        }
        return;
    }

    handleInitData(data: any[], { type = "list", mapKey = "key", mapValueKey = "value", sync = [] }: any) {
        if (type === "list") return data;
        let retMap = {};
        let mapKeyList = [];
        if (mapKey.indexOf(",") > -1 && sync) {
            //本地数据无headers时同步数据处理
            mapKeyList = mapKey.split(",");
            mapKeyList.map((valueKey: string) => {
                retMap[valueKey] = this.syncKey(valueKey, sync);
            });
        } else {
            data?.map?.((item: any) => {
                const key = item[mapKey];
                if (key) {
                    retMap[key] = mapValueKey ? item[mapValueKey] : item;

                    //同步数据  增加取值key, 针对输出key与取值key不同的情况，简化配置
                    const value = this.syncKey(key, item.sync || sync);
                    if (typeof value !== "undefined") retMap[key] = value;
                    //适配参数为null时，删除字段
                    if (retMap[key] === "null") delete retMap[key];
                } else {
                    retMap = item;
                }
            });
        }
        return retMap;
    }

    moduleName: string;

    itemProps: any;

    init(data: any, props: any): IDataType {
        const { initHandleValue, type } = props;
        this.itemProps = props;
        const handleData: any = this.handleInitData(data, props);

        if (initHandleValue) {
            const { merge, mergeBefore, ...other } = this.mHandle.execValue(initHandleValue, { ...props });
            if (type === "list") {
                return { loading: false, data: [...mergeBefore, ...handleData, ...merge] };
            } else {
                const execData = { ...mergeBefore, ...handleData, ...other, ...merge };
                return { loading: false, data: execData };
            }
        }

        return { loading: false, data: handleData };
    }

    /**
     * 同步数据
     */
    syncData(headers: any = {}, { type, mapKey = "key", sync, initData }: any) {
        if (type === "list") return;
        if (initData && initData.length > 0) {
            initData.map((item: any) => {
                const key = item[mapKey];
                // console.log("[Handle]:initData", item, key, mapKey, headers, sync);
                if (headers.hasOwnProperty(key)) this.syncKey(key, item.sync || sync, headers[key]);
            });
        } else {
            Object.keys(headers).map((key) => {
                this.syncKey(key, sync, headers[key]);
            });
        }
    }

    exec(headers: any, data: IDataType, props: any, callback: any = () => {}): any | IDataType {
        const typeHeaders = Array.isArray(headers);
        //触发模块获取数据
        if (props.module && headers.module != false) {
            const { ename } = props.uiItem.info;
            this.moduleName = ename;
            ModuleMgr.Instance.register(ename, this.mHandle, { pageSize: props.uiItem.props.pageSize || 10 }, headers);
            // return;
        }
        if (Object.keys(headers).length === 0) {
            if (Array.isArray(headers)) {
                data.data = headers;
            }
            return data;
        }
        let ret: IDataType;
        // console.log("[Handle]", headers, data, props);
        if (headers.data && headers.start) {
            ret = { ...data, ...headers };
            headers = headers.data;
        } else {
            ret = { ...data };
        }
        if (Array.isArray(headers)) {
            //如果为传递的参数只有一个数组，意味着修改数据，直接替换数据
            if (typeHeaders) ret.data = headers;
            //如果为分页
            else if (ret.start && ret.start > 1) ret.data = [...data.data, ...headers];
            else ret.data = headers;
        } else {
            this.syncData(headers, props);
            ret.data = { ...data.data, ...headers };
        }
        return ret;
    }

    destory() {
        if (this.moduleName) ModuleMgr.Instance.unregister(this.moduleName);
    }
}

export default Handle;
