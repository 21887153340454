import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import "swiper/dist/css/swiper.min.css";
import "./JSwiper.scss";
import IUIItem from "src/engine/interface/IUIItem";
import Utils from "src/lib/utils/Utils";
import AutoContext from "src/engine/decorator/AutoContext";
import PanelContext, { IPanelContext } from "src/engine/context/PanelContext";
import { noop } from "../JDragLine/Utils";
const Swiper = require("swiper/dist/js/swiper.js");

interface IJSwiper extends IBaseUI {
    //基本属性，对应Basic(Swiper一般选项),网址下 https://www.swiper.com.cn/api/parameters/42.html
    base: { [key: string]: any };
    auto: { [key: string]: any };
    //数据
    data: any[];
    //扩展UI
    expendUI: IUIItem[];
    isLinear?: boolean;
    /** 是否打开分页器 */
    pagination?: boolean;
    /** 分页器类型  https://swiper.com.cn/api/pagination/299.html */
    paginationType?: string;
    cb?: any; // slideChange后的回调
}
interface IState {
    realIndex: number;
}

@UIManifest.declare("JSwiper", "JSwiper组件", UIManifest.Type.JJ)
class JSwiper extends BaseUI<IJSwiper, IState> {
    view: React.RefObject<HTMLDivElement>;

    state = {
        realIndex: 0,
    };

    swiper: any;
    swiperPagi: any;

    componentDidMount() {
        this.swiper = new Swiper(this.view, this.getConfig());
        this.slideChange();
        this.swiper.on("slideChange", () => {
            this.slideChange();
        });
    }

    slideChange = () => {
        const realIndex = this.swiper.realIndex;
        this.setState({ realIndex });
        this.props.cb && this.props.cb(realIndex);
    };

    getConfig() {
        const { base, pagination, paginationType, auto } = this.props;
        const ret = { ...base };
        if (pagination) {
            ret.pagination = {
                el: this.swiperPagi,
                paginationType,
            };
        }
        if (ret.effect === "coverflow") ret.slidesPerView = "auto";
        if (ret.autoplay && auto) {
            ret.autoplay = { ...auto };
        }
        return ret;
    }

    exec(name: string, ...other: any) {
        if (!this.swiper) return;
        return this.swiper[name](...other);
    }

    slideTo = (callback: any, value: any) => {
        if (typeof callback === "number") {
            value = callback;
            callback = noop;
        }
        const { base = {} } = this.props;
        if (typeof (value * 1) !== "number") return;
        if (base.loop) {
            this.exec("slideToLoop", value);
        } else {
            this.exec("slideTo", value);
        }
        callback();
    };

    getExpendConfig() {
        const { data, dataSource } = this.props;
        return {
            slideNext: this.exec.bind(this, "slideNext"),
            slidePrev: this.exec.bind(this, "slidePrev"),
            realIndex: this.state.realIndex,
            slideTo: this.slideTo,
            data: data || dataSource,
            style: { zIndex: 1 },
        };
    }

    renderListItem(ret: any, data: any, key: number) {
        return <div className="swiper-slide">{ret}</div>;
    }

    getData(props: IJSwiper) {
        return props.data || props.dataSource || [];
    }

    componentDidUpdate(preProps: IJSwiper) {
        //如果数据变化,防止个数变化，但展示有误
        if (this.getData(this.props).length !== this.getData(preProps).length) {
            // return data;
            // console.log('[JSwiper]:', this.swiper);
            this.swiper.updateSlides();
        }
    }

    mPanelContext: IPanelContext;

    @AutoContext(PanelContext, "mPanelContext")
    render() {
        const { expendUI = [], isLinear } = this.props;
        const { realIndex } = this.state;
        return (
            <div className="react-swiper" style={this.getStyle()}>
                <div className="swiper-container" ref={Utils.ref(this, "view")}>
                    <div className={`swiper-wrapper ${isLinear ? "swiper-linear" : ""}`}>
                        {this.renderDataItemsByContext(this.mPanelContext, undefined, undefined, this.renderListItem, { realIndex })}
                        {/* <div className="swiper-slide">slider1</div>
                        <div className="swiper-slide">slider2</div>
                        <div className="swiper-slide">slider3</div> */}
                    </div>
                    <div className="swiper-pagination" ref={Utils.ref(this, "swiperPagi")} />
                </div>

                {this.renderChildren(expendUI, this.getExpendConfig())}
            </div>
        );
    }
}

export default JSwiper;
