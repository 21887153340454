import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";
import {
    IMMEDIATE_SCRIPT_FLAG,
    MOUNTED_IMMEDIATE_SCRIPT_FLAG,
    UNMOUNT_IMMEDIATE_SCRIPT_FLAG,
    EVENT_RESIZE_SCRIPT_FLAG,
    POLLING_IMMEDIATE_SCRIPT_FLAG,
} from "src/lib/utils/Const";

enum ACT_TIME {
    INIT = "constructor",

    MOUNTED = "componentDidMount",

    UNMOUNT = "componentWillUnmount",

    RESIZE = "onresize",

    POLLING = "_pollingImmediateScriptRender",
}

interface ICallbackScript {
    // 是否修改当前组件的props : 更新UI组件的state以达到更新目标组件props的目的
    changeProps?: boolean;

    // 是否渲染完成再触发
    mounted?: boolean;

    // 执行时机
    actTime?: ACT_TIME;

    // 轮询时间
    pollingTime?: number;
}

const LIFECYCLE = {
    [ACT_TIME.INIT]: IMMEDIATE_SCRIPT_FLAG,
    [ACT_TIME.MOUNTED]: MOUNTED_IMMEDIATE_SCRIPT_FLAG,
    [ACT_TIME.UNMOUNT]: UNMOUNT_IMMEDIATE_SCRIPT_FLAG,
    [ACT_TIME.RESIZE]: EVENT_RESIZE_SCRIPT_FLAG,
    [ACT_TIME.POLLING]: POLLING_IMMEDIATE_SCRIPT_FLAG,
};

/**
 *
 *
 * @class ImmediateScript
 */
@UIManifest.declare("ImmediateScript", "执行时机脚本", UIManifest.Type.scriptCall)
export default class ImmediateScript extends Script<ICallbackScript> {
    exec(info: IInfo) {
        const { changeProps, mounted = false, actTime = ACT_TIME.MOUNTED, pollingTime } = this.props;
        if (mounted) {
            info.name = MOUNTED_IMMEDIATE_SCRIPT_FLAG;
        } else {
            info.name = LIFECYCLE[actTime] ?? IMMEDIATE_SCRIPT_FLAG;
        }
        info.params = { changeProps, pollingTime };
        return true;
    }
}
