export enum CalcType {
    /**
     * 加上
     */
    ADD = "+",
    /**
     * 减去
     */
    SUB = "-",
    /**
     * 乘以
     */
    MUL = "x",
    /**
     * 除以
     */
    DIV = "/",
    /**
     * 取余
     */
    BEY = "%",

    /**
     * 整除
     */
    INTDIV = "int_div",
}

class CalcScript {
    static calcExt(type: string, val1: string, val2: string, key: string): any {
        //
        if (key.length > 0 && key === val2.slice(0, key.length)) {
            return CalcScript.calc(type + key, val1, val2.slice(key.length, val2.length));
        }
        return false;
    }

    /**
     * 计算
     * @param type
     * @param val1
     * @param val2
     */
    static calc(type: string | CalcType = CalcType.ADD, val1: string, val2: string) {
        switch (type) {
            case CalcType.ADD:
                return Number(val1) + Number(val2);
            case CalcType.SUB:
                return Number(val1) - Number(val2);
            case CalcType.MUL:
                return Number(val1) * Number(val2);
            case CalcType.DIV:
                return Number(val1) / Number(val2);
            case CalcType.BEY:
                return Number(val1) % Number(val2);
            case CalcType.INTDIV:
                return Math.floor(Number(val1) / Number(val2));
            case ">":
                return Number(val1) > Number(val2) || CalcScript.calcExt(type, val1, val2, "=");
            case ">=":
                return Number(val1) >= Number(val2);
            case "<":
                return Number(val1) < Number(val2) || CalcScript.calcExt(type, val1, val2, "=");
            case "<=":
                return Number(val1) <= Number(val2);
            case "!":
                return (
                    CalcScript.calcExt(type, val1, val2, "!") ||
                    CalcScript.calcExt(type, val1, val2, "=") ||
                    CalcScript.calcExt(type, val1, val2, "c") ||
                    CalcScript.calcExt(type, val1, val2, "n") ||
                    CalcScript.calcExt(type, val1, val2, "u")
                );
            case "!=":
                if (val1 === undefined) return false;
                return val1 + "" !== val2 + "";
            case "!!":
                return typeof val1 === "undefined" || val1 === null;
            case "!u":
                return typeof val1 !== "undefined" && val1 !== "";
            case "=c":
                return (val1 || "").indexOf(val2) >= 0;
            case "!c":
                return (val1 || "").indexOf(val2) < 0;
            case "=":
                return CalcScript.calcExt(type, val1, val2, "=") || CalcScript.calcExt(type, val1, val2, "c") || CalcScript.calcExt(type, val1, val2, "n");
            case "==":
                const list = (val2 + "").split(",");
                let bool = false;
                for (const item of list) {
                    bool = val1 + "" === item;
                    if (bool) break;
                }
                return bool;
            case "=n":
                return Number(val1) % Number(val2) === 0;
            case "!n":
                return Number(val1) % Number(val2) === 1;
            default:
                return;
        }
    }
}

export default CalcScript;
