import Script from "src/component/script/Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";
import Utils from "src/lib/utils/Utils";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import Formmatter from "../DataToExcel/Formmatter";
import CalcScript from "../NumberCalcScript/CalcScript";

interface IArrayHandleScript {
    sortBy?: string;
    sortDes?: "aesc" | "desc";
    classifyKey?: string; //分类key名
    formmatter?: string; //分类key处理
    classifyMap?: boolean; //分类之后返回为 map类型（默认为list）
    splitnumber?: number;
    type?: string;
    maps?: Array<{ key: string; value: string; formmatter?: string }>;
    map?: { [key: string]: any };
    expendKey?: string; //展开项
    upkey?: string;
    removeKeys?: string[];
    mergeKeys?: string[];
    check?: Array<{ key: string; value: string; type: string }>;
    checkType?: "|" | "&";
}

/**
 *
 *
 * @class ArrayHandleScript
 */
@UIManifest.declare("ArrayHandleScript", "数组处理脚本", UIManifest.Type.Script)
class ArrayHandleScript extends Script<IArrayHandleScript> {
    //根据key值进行分类，生成二级数组[{classifyKey:[classifyKey],list:[]}]
    classify = (config: IInfo) => {
        const { classifyKey, formmatter, classifyMap } = this.props;
        if (!classifyKey) return;
        const dataMap = {};
        config.value.map((item: any, index: number) => {
            let value = item[classifyKey];
            if (formmatter) {
                value = Utils.format(value, formmatter);
            }
            if (!dataMap[value]) {
                dataMap[value] = { classifyKey: value, list: [] };
            }
            dataMap[value].list.push(item);
        });
        config.value = [];
        for (const k in dataMap) {
            if (classifyMap) {
                config.value = dataMap;
            } else {
                if (dataMap[k]) {
                    config.value.push(dataMap[k]);
                }
            }
        }
    };

    /**
     * 聚合
     * 将列表数据根据指定字段进行聚合
     * @param value
     */
    agg(value: any[]) {}

    /**
     * 数据替换
     * 源数据   value:[{id:"1", extItem:"old"},{id:"2", extItem:"old"}]
     * 替换项   map:{extItem: target}
     * 结果     result:[{id:"1", extItem:"target"},{id:"2", extItem:"old"}]
     *
     * 替换条件 check:[{key:"id",type:"==",value:"1"}] 或 [{key:"_index",,type:"==",value:"1"}]
     * @param value
     */
    dataExtend(value: any) {
        let { check = [], map = {} } = this.props;
        let index = Number(check[0]?.value);
        if (!Array.isArray(value) && value?.map) {
            if (value?.index) index = value.index;
            map = value.map;
            value = value.data;
        }
        if (Array.isArray(value)) {
            const { checkType = "|" } = this.props;
            let keys = Object.keys(map);
            // 当替换条件是索引项时，直接替换对应索引项的数据，无需map遍历
            if (index && check[0]?.key === "_index" && index < value.length) {
                keys.forEach((key) => {
                    value[index][key] = map[key];
                });
                return value;
            }
            value = value.map((item) => {
                item = { ...item };
                // 满足条件时，才替换数据；无替换条件，默认全部替换
                if (this.checkResult(check, checkType, item)) {
                    keys.forEach((key) => {
                        item[key] = map[key];
                    });
                }
                return item;
            });
        }
        return value;
    }

    checkResult(check: any[], checkType: string, item: object) {
        let bool = true;
        for (let checkItem of check) {
            bool = CalcScript.calc(checkItem.type, PropsUtils.getValue(item, checkItem.key), checkItem.value);
            if (checkType == "|" && bool) break;
            if (checkType == "&" && !bool) break;
        }
        return bool;
    }

    /**
     * 二维数组展开成一维
     * @param value
     */
    listItemConcat(value: any[]) {
        if (Array.isArray(value)) {
            let res: any[] = [];
            value.forEach((item) => {
                res = [...res, ...item];
            });
            return res;
        }
        return value;
    }

    /**
     * 两个数组合并
     * @param value
     */
    arrayConcat(value: any) {
        if (typeof value !== "object") return value;
        const maps = value.maps || [];
        const val = value.value || [];
        return [...val, ...maps];
    }

    reverse(value: []) {
        if (!value || !Array.isArray(value)) return value;
        const cache = [...value];
        return cache.reverse();
    }

    /**
     * 对象转数组项
     * @param value
     */
    mapToListItem(value: object) {
        return [value];
    }

    listToMap(value: any[]) {
        if (Array.isArray(value)) {
            const { maps = [] } = this.props;
            const ret: any = {};
            // maps.map((map, index) => ret[map.value] = value[index]);
            value.map((item) => maps.map((map) => (ret[item[map.key]] = map.value === "_all" ? item : item[map.value])));
            return ret;
        }
        return value;
    }

    /**
     * 对数组进行排序
     * @param value
     */
    listSort(value: any[]) {
        const { sortDes = "desc", sortBy, sortByBy } = this.props as any;
        if (Array.isArray(value) && sortBy) {
            const newValue = [...value];
            const calc = (a: any, b: any, sortKey: string) => {
                if (sortDes === "desc") return b[sortKey] - a[sortKey];
                else return a[sortKey] - b[sortKey];
            };
            newValue.sort((a: any, b: any) => {
                let ret = calc(a, b, sortBy);
                if (sortByBy && ret === 0) {
                    ret = calc(a, b, sortByBy);
                }
                return ret;
            });
            return newValue;
        }
        return value;
    }

    /**
     * 数组项转对象
     * @param value
     */
    listItemToMap(value: any[]) {
        if (Array.isArray(value)) {
            const { maps = [] } = this.props;
            value = value.map((item) => {
                const ret: any = {};
                maps.map((map) => (ret[map.value] = map.value === "_all" ? item : this.itemFormmater(item[map.key], map.formmatter)));
                return ret;
            });
        }
        return value;
    }

    /**
     * 数据展开
     * @param value
     * @param maps
     */
    dataExpend(value: any[], maps: object = {}) {
        const { expendKey, removeKeys = [] } = this.props;

        if (expendKey && Array.isArray(value)) {
            // maps.map((map, index) => ret[map.value] = value[index]);
            return value.map((item: any) => {
                const ret = { ...item, ...item[expendKey] };
                removeKeys.map((key: string) => delete ret[key]);
                return ret;
            });
        }
        return value;
    }

    mapToList(value: any) {
        // console.log('[ArrayHandleScript]:mapToList', value);
        if (typeof value === "object" && !Array.isArray(value)) {
            const { maps = [] } = this.props;
            // console.log('[ArrayHandleScript]:', value, maps);
            value = maps.map((map) => value[map.value]);
            // console.log('[ArrayHandleScript]:end', value, maps);
        }
        return value;
    }

    /**
     * 数组项格式转换
     * @param value
     */
    listItemFormmater(value: any[]) {
        if (Array.isArray(value)) {
            const { maps = [] } = this.props;
            value = value.map((item) => {
                maps.map((map) => (item[map.key] = this.itemFormmater(item[map.key], map.formmatter)));
                return item;
            });
        }
        return value;
    }

    /**
     * 数组项增加
     * @param value
     */
    listItemInsert(value: any) {
        let { insertMaps } = this.props as any;
        let { maps = [] } = this.props;
        if (!Array.isArray(value) && value?.insertMaps) {
            insertMaps = value?.insertMaps;
            value = value.data;
            for (let i in insertMaps) {
                let map: { key: string; value: any } = { key: i, value: insertMaps[i] };
                // 避免添加重复数据
                // maps = maps.filter((m) => m.key != map.key)
                maps = Array.from(new Set(maps));
                maps.push(map);
            }
        }
        if (Array.isArray(value)) {
            value = value.map((item) => {
                maps.forEach((map) => {
                    item[map.key] = map.value;
                });
                return item;
            });
        }
        return value;
    }

    /**
     * 数组项删除
     * @param value
     */
    listItemDelete(value: any[]) {
        if (Array.isArray(value)) {
            const { removeKeys = [] } = this.props;
            value = value.map((item) => {
                item = { ...item };
                removeKeys.forEach((key) => delete item[key]);
                return item;
            });
        }
        return value;
    }

    /**
     * 数组项合并
     * 也可认为是给数组项增加前缀或后缀
     * [{v1:a, v2:b, v3:c}] -> [{v1:a, v2:b, v3:c, mergeValue:acb}] (mergeKeys:[v1, v3, v2])
     * @param value
     */
    listItemMerge(value: any) {
        if (Array.isArray(value)) {
            const { mergeKeys = [] } = this.props;
            value = value.map((item) => {
                item = { ...item };
                item.mergeValue = mergeKeys.reduce((acc, current) => {
                    return `${acc}` + `${item[current]}`;
                }, "");
                return item;
            });
        }
        return value;
    }

    listAggByKey(value: any) {
        if (!value) return value;
        let getValue = value.value;
        if (Array.isArray(getValue) && value.rights) {
            const { aggKey = "id" } = this.props as any;
            let caches = {};
            if (Array.isArray(value.rights))
                (value.rights as Array<any>).reduce((total, b) => {
                    total[b[aggKey]] = b;
                    return total;
                }, caches);
            else caches = value.rights;
            getValue = getValue.map((item) => {
                const find = caches[item[aggKey]] ?? {};
                if (typeof find !== "object") return { ...item, [`${aggKey}_agg`]: find };
                return { ...item, ...find };
            });
        }
        return getValue;
    }

    /**
     * 数组项累加
     * mergeKeys 和 removeKeys 只能二选一
     * - (mergeKeys:[v1, v2, v3]) [{v1:1, v2:2, v3:5, v4:noNeed}] -> [{v1:1, v2:2, v3:5, v4:noNeed, total:8}]
     * - (removeKeys:[v1, v4])     [{v1:1, v2:2, v3:5, v4:noNeed}] -> [{v1:1, v2:2, v3:5, v4:noNeed, total:7}]
     * @param value
     * @returns
     */
    listItemAcc(value: any) {
        const { mergeKeys = [], removeKeys = [] } = this.props;
        const firstMap = value?.[0];
        let optionKeys = firstMap ? Object.keys(firstMap) : [];

        if (mergeKeys.length !== 0) {
            optionKeys = [...mergeKeys];
        } else if (removeKeys.length !== 0) {
            optionKeys = optionKeys.filter((item) => !removeKeys.includes(item));
        }

        if (Array.isArray(value)) {
            value = value.map((item) => {
                item = { ...item };
                item.total = optionKeys.reduce((acc, current) => {
                    return Number(acc) + Number(item[current]);
                }, 0);
                return item;
            });
        }
        return value;
    }

    /**
     * 数组项Key映射
     * @param value
     */
    listItemKeyMap(value: object[]) {
        if (Array.isArray(value)) {
            const { map = {} } = this.props;
            value = value.map((item) => {
                const keys = Object.keys(item);
                const newData = {};
                keys.forEach((key) => {
                    let newKey = map[key] || key;
                    newData[newKey] = item[key];
                });
                return newData;
            });
        }
        return value;
    }

    /**
     * 数组过滤为单值数组
     */
    arrToSingleArr(value: any) {
        let { expendKey, numToString } = this.props as any;
        if (!Array.isArray(value) && value?.expendKey) {
            expendKey = value?.expendKey;
            value = value.data;
        }
        if (Array.isArray(value) && expendKey) {
            const ret: any = [];
            const cache = {};
            value.map((item) => {
                let itemValue = PropsUtils.getValue(item, expendKey);
                if (typeof itemValue !== "undefined" && !cache[itemValue]) {
                    if (numToString) itemValue += "";
                    ret.push(itemValue);
                    cache[itemValue] = true;
                }
            });
            value = ret;
        }
        return value;
    }

    // 单项类型转换
    itemFormmater(item: any, formmatter?: string) {
        return formmatter ? Formmatter.formmatter(item, formmatter) : item;
    }

    /**
     * 数组过滤
     * @param value
     */
    dataFilter(value: any) {
        const { optType, left, filterNullOld } = this.props as any;
        let { rights } = this.props as any;
        if (!Array.isArray(value) && value?.rights) {
            rights = value.rights;
            value = value.data;
        }
        // console.log("[ArrayHandleScript]", value, rights);
        // 判断某一行是否保留，默认返回true
        let newValue = [];
        if (Array.isArray(value) && optType && left && (rights || !!optType)) {
            const maps = {};
            newValue = value.filter((row, rowIndex) => {
                if (optType === "_self") {
                    const value = row[left];
                    if (maps[value]) {
                        return false;
                    }
                    maps[value] = true;
                    return true;
                } else if (optType === "_index") {
                    return rowIndex == Number(rights);
                }
                return CalcScript.calc(optType, PropsUtils.getValue(row, left), rights);
            });
        }
        if (filterNullOld && newValue.length === 0) {
            return value;
        }
        return newValue;
    }

    // 子项字段提升
    upKeywords(values: any) {
        const newValue: any = Utils.deepClone(values);
        const { button } = newValue;
        if (Array.isArray(button)) {
            for (const item of button) {
                const { sub_button } = item;
                item.sub_button = sub_button.list;
            }
            console.log(newValue);
        }
        return newValue;
    }

    listOpt(value: any) {
        const { optType = "join" } = this.props as any;
        let { args = [] } = this.props as any;
        if (!Array.isArray(value) && value?.rights) {
            args = value.rights;
            if (!Array.isArray(args)) args = [args];
            value = value.data;
        }
        if (value[optType]) {
            return value[optType](...args);
        }
        return value;
    }

    exec(config: IInfo, callback: (info: IInfo) => any) {
        const { sortBy, sortDes = "desc", splitnumber, type } = this.props;

        if (this[type || "unde"]) {
            config.value = this[type || "unde"](config.value);
            return true;
        }
        if (Array.isArray(config.value)) {
            if (splitnumber) {
                config.value = config.value.slice(0, splitnumber);
            }
            if (sortBy) {
                config.value = config.value.sort((a: any, b: any) => {
                    if (sortDes === "desc") return b[sortBy] - a[sortBy];
                    else return a[sortBy] - b[sortBy];
                });
            }
            this.classify(config);
        }
        return true;
    }
}

export default ArrayHandleScript;
