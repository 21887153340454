import Script from "../Script";
import UIManifest from "src/engine/ui/UIManifest";
import Utils from "src/lib/utils/Utils";
import Formatter from "../DataToExcel/Formmatter";
import IPConfig from "src/IPConfig";

interface IStringScript {
    //操作类型
    type: string;
    //字符串
    str: string;
    //线上字符串
    releaseStr?: string;
    //格式化类型
    formatterType?: string;
    //json操作类型
    jsonType?: "toJSON" | "fromJSON";
    //包含中文的解码与编码(后期可根据Node.js版本变化扩充类型)
    codeFrom?: "ascii" | "utf8" | "utf16le" | "base64" | "binary";
    codeTo?: "ascii" | "utf8" | "utf16le" | "base64" | "binary";
    //
    numberList?: any[];
    lessType?: "auto" | "default";
    valueLen?: number;
}

/**
 *
 *
 * @class StringScript
 */
@UIManifest.declare("StringScript", "字符串处理", UIManifest.Type.Script)
class StringScript extends Script<IStringScript> {
    /**
     * 字符串生成
     * @param url
     * @param headers
     */
    str(value: any) {
        let { str = "" } = this.props;
        if (this.props.releaseStr && IPConfig.release) {
            str = this.props.releaseStr;
        }
        if (str.indexOf("xxx") >= 0) {
            str = str.replace("xxx", value);
        } else if (value && str.indexOf("[") >= 0) {
            const list = Utils.parser(/\[(.+?)\]/gim, str, 1);
            list.map((item) => {
                if (value[item] !== undefined) {
                    str = str.replace(`[${item}]`, value[item]);
                }
            });
        }
        return str;
    }

    /**
     * 字符串替换
     * @param value
     */
    replace(value: any) {
        const { replace } = this.props as any;
        if (value?.replace && replace) {
            replace.map(({ key, value: toValue = "" }: any) => {
                value = value.replace(new RegExp(key, "gm"), toValue);
            });
        }
        return value;
    }

    /**
     *
     * @param value
     */
    split(value: any) {
        const { splitType = "," } = this.props as any;
        return value?.split?.(splitType) ?? value;
    }

    join(value: any) {
        const { splitType = "," } = this.props as any;
        return value?.join?.(splitType) ?? value;
    }

    /**
     * json处理
     * @param value
     */
    json(value: any) {
        const { jsonType = "toJSON" } = this.props;
        try {
            return jsonType === "toJSON" ? JSON.stringify(value) : JSON.parse(value);
        } catch (error) {
            return value;
        }
    }

    /**
     * 格式化字符串
     * @param value
     */
    formatter(value: any) {
        const { formatterType } = this.props;
        return formatterType ? Formatter.formmatter(value, formatterType) : value;
    }

    handle(value: any) {
        const { type = "str" } = this.props;
        if (this[type]) {
            value = this[type](value);
        }
        return value;
    }

    randomString() {
        const { valueLen = 16 } = this.props;
        return Utils.randomString(valueLen);
    }

    /**
     *
     * numberList[]参数
     * num: 10, show: 0+ ; 输入 value = 55 => 50+;
     * num: 100, show: 00+ ; 输入 value = 515 => 500+;
     * num: 1000, show: k+ ; 输入 value = 5155 => 5k+;
     * num: 10000, show: w+ ; 输入 value = 51550 => 5w+;
     * @returns
     */
    numberToless(value: any) {
        const nums = value * 1;
        if (nums > 0) {
            const { numberList = [], lessType = "auto" } = this.props;
            if (numberList.length === 0) return value;

            let cacheNum = 0,
                cacheShow = "+",
                cacheCShow = "+",
                fixedNum = 1;
            for (const item of numberList) {
                const { num, show = "+", cShow, fixedSize } = item;
                if (nums > num) {
                    cacheNum = num;
                    cacheShow = show;
                    cacheCShow = cShow ?? show;
                    fixedNum = fixedSize;
                } else {
                    break;
                }
            }
            if (cacheNum === 0) return value;
            if (lessType === "default") {
                return cacheNum + cacheShow;
            }
            if (nums % cacheNum === 0) cacheShow = cacheCShow;
            let item = 1;
            for (let i = 1; i <= fixedNum; i++) {
                item *= 10;
            }
            return Number(Math.floor((nums * item) / cacheNum) / item).toFixed(fixedNum) + cacheShow;
        }
        return value;
    }

    /**
     * 字符串编码转换
     */
    endecode(value: any) {
        const { codeFrom, codeTo } = this.props;
        return Buffer.from(value, codeFrom).toString(codeTo);
    }
}

export default StringScript;
