import Script from "../Script";
import UIManifest from "src/engine/ui/UIManifest";
import IExecConfig from "src/component/props/PropsGGEditor/exec/IExecConfig";
// import Utils from "src/lib/utils/Utils";

interface IHandleScript {
    //*xxx*
    styleName: string;

    compareValue: string;

    type: string;

    valueType?: "self" | "props";
}

/**
 *
 *
 * @class AddStyleTab
 */
@UIManifest.declare("AddStyleTab", "添加style", UIManifest.Type.ScriptAddProps)
class AddStyleTab extends Script<IHandleScript> {
    protected handleConfig(config: IExecConfig) {
        config["name"] = "_computeStyle";
        return config;
    }

    handle(value: any, props: any, config: IExecConfig) {
        const { styleName = "" } = this.props;
        return styleName.split(",");
    }
}

export default AddStyleTab;
