export const noop = () => { }

export function unique(arr: any[], compare = (a: any, b: any) => a === b) {
    const result = [];
    for (let i = 0, len = arr.length; i < len; i++) {
        const current = arr[i]
        if (result.findIndex(v => compare(v, current)) === -1) {
            result.push(current)
        }
    }
    return result
}

export const checkArrayWithPush = (target: any, key: number, value: any) => {
    if (Array.isArray(target[key])) {
        target[key].push(value)
    } else {
        target[key] = [value]
    }
}

export const createCoreData = ({ node, deltaX, deltaY }: any, { originX, originY, x, y }: any) => {
    return {
        node,
        deltaY,
        deltaX,
        originX: originX || x,
        originY: originY || y,
        x,
        y,
    }
}

export const getMaxDistance = (arr: any) => {
    const num = arr.sort((a: any, b: any) => a - b)
    return num[num.length - 1] - num[0]
}

export default {
    noop,
    unique,
    createCoreData,
    checkArrayWithPush,
}