import Exec from "./Exec";
import IUIItem from "src/engine/interface/IUIItem";
import IDataType from "src/engine/SourceCenter/IDataType";
// IDataType
import { IGuiData } from "../props/PropsGGEditor/exec/IExecConfig";
import ExecScript from "../props/PropsGGEditor/exec/ExecScript";
import Utils from "src/lib/utils/Utils";

export interface IHeaders {
    /**
     * 开始页
     */
    start?: number;
    /**
     * 分页大小
     */
    count?: number;

    [key: string]: any;
}

/**
 *
 *
 * @class SourceHandle
 */
class SourceHandle {
    /**
     * 执行器
     */
    exec: Exec;

    mUIItem: IUIItem;

    props: any;

    /**
     * 管理器
     */
    manager: any;

    mLastDataType: IDataType;

    constructor(uiItem: IUIItem, exec: Exec, props: any, manager?: any) {
        // const exec: any = SourceHandle.Handles[uiItem.ui as string];
        this.exec = exec;
        this.exec.setHandle(this);
        this.mUIItem = uiItem;
        this.props = props;
        this.manager = manager;
    }

    setManager(manager: any) {
        this.manager = manager;
    }

    execManagerSource(headers: any) {
        this.manager.execSource(this.mUIItem.info.ename, headers);
    }

    getManagerSource(ename: string = this.mUIItem.info.ename) {
        return this.manager.getSourcesData(ename);
    }

    getEvents() {
        return this.exec.propsListener();
        return ["initData", "initHandle"];
    }

    updateProps(props: any) {
        this.props = props;
        this.exec.propsUpdate();
        // //通知更新初始化数据
        // this.update({}, this.initData());
    }

    execValue(gValue: IGuiData, props: any = {}): any {
        if (gValue && this.manager)
            return new ExecScript(
                gValue || {},
                { sourceContext: { manager: this.manager, mManager: this.manager, execSource: this.manager.execSource } },
                props
            ).exec();
        return {};
    }

    /**
     * 初始化数据
     */
    initData(): IDataType {
        const { initData = {}, initHandle, initLoading, initValue } = this.props;
        //执行初始化
        const dataType = this.exec.init(initData, this.props);
        if (initValue) {
            dataType.data = { ...dataType.data, ...this.execValue(initValue, dataType) };
        }
        //执行逻辑，进行数据初始化
        if (initHandle) {
            const ret = initHandle(dataType.data);
            if (ret) dataType.data = ret;
        }
        /**
         * 以前写法： this.mLastDataType = { loading: initLoading , ...dataType };
         * dataType 中有 loading , 始终都会覆盖 initLoading . 意义不明。
         */
        if (initLoading === true || initLoading === false) {
            this.mLastDataType = { ...dataType, loading: initLoading };
        } else {
            this.mLastDataType = { ...dataType };
        }
        return this.mLastDataType;
    }

    /**
     * 重置刷新接口
     */
    refresh(props: any = this.props) {
        this.props = props;
        this.execSource({}, this.mLastDataType);
    }

    async execSource(headers: any = {}, data: IDataType, callback: any = () => {}, clear?: boolean | string) {
        const { dataValue } = this.props;
        //清空数据
        if (clear && clear !== "false") {
            const initData = this.initData();
            this.update({}, initData);
            if (clear === "true" || clear === true) {
                data = initData;
            }
        }
        //处理参数
        headers = this.handleHeaders(headers, data, clear === true || clear === "true");

        //处理执行器处理
        let ret = await this.exec.exec(headers, data, this.props, callback);

        if (!ret) return;

        //处理结果
        // this.handleData(headers, data, callback)?.then((ret: IDataType) => {
        const pageNum = headers.start || headers.pageNum || 1;
        const pageSize = headers.count || headers.pageSize || 10;
        let end = false;
        const { data: retData = {} } = ret;
        const { listKey } = this.props;
        let list = retData.DATA || retData.data || retData.Data || [];
        if (listKey) list = Utils.getValue(retData, listKey.split(".")) || [];
        if (pageNum && Array.isArray(list)) {
            end = list.length < pageSize;
            if (ret.data) ret.data.end = end;
            else ret.data = { end };
        }
        
        //如果定义逻辑处理数据，
        if (dataValue) {
            ret = { ...ret, ...this.execValue(dataValue, { data: { data: ret.data, headers } }) };
        }

        if (this.props.isPagination && data && pageNum > 1) {
            if (Array.isArray(data.data)) {
                const frist = data.data.splice(0, (pageNum - 1) * pageSize);
                ret.data = [...frist, ...ret.data];
            } else if (data.data) {
                let oldData = data.data.DATA;
                if (listKey) oldData = Utils.getValue(data.data, listKey.split("."));
                if (oldData) {
                    let lastvalue = [...oldData];
                    // 兼容分页请求出错的情况
                    if (Array.isArray(list)) lastvalue = [...oldData, ...list];
                    if (listKey) Utils.setValue(ret.data, listKey.split("."), lastvalue);
                    else ret.data.DATA = lastvalue;
                }
            }
        }
        //通知manager保存
        this.update(headers, ret);

        callback(ret.data, ret.data);
        // });
    }

    update(headers: any, ret: IDataType) {
        this.mLastDataType = ret;
        const { info, props } = this.mUIItem;
        //通知manager保存
        this.manager?.update(info.ename, headers, ret);

        if (props.syncSource) this.manager?.execSource(props.syncSource, ret.data);
    }

    /**
     * 处理参数
     * @param headers 新参数
     * @param oldHeaders 旧参数
     */
    private handleHeaders(headers: IHeaders, data: IDataType = { loading: false }, clear?: boolean) {
        const { headersValue } = this.props;
        if (Array.isArray(headers)) return headers;
        let { initHeaders = {} } = this.props;
        if (Array.isArray(headers)) return headers;
        //兼容数据参数进行处理后，再添加
        initHeaders = this.exec.handleInitHeaders(initHeaders, this.props);
        //处理参数
        if (clear) headers = { ...initHeaders, ...headers };
        else headers = { ...initHeaders, ...data.headers, ...headers };
        //处理器处理参数
        headers = this.exec.handleHeaders(headers, this.props);
        //执行逻辑，处理参数
        if (headersValue) {
            const { merge, mergeBefore, ...other } = this.execValue(headersValue, { headers, ...this.props });
            // 增加被覆盖还是覆盖别人
            headers = { ...mergeBefore, ...headers, ...other, ...merge };
        }

        // if (headersValue) {
        //     headers = { ...this.execValue(headersValue, headers), ...headers };
        // }
        return headers;
    }

    // private async handleData(headers: any, data: IDataType, callback: any = () => {}) {
    //     // const { execValue } = this.props;
    //     return await this.exec.exec(headers, data, this.props, callback);
    //     // if (!ret) return;
    //     // //判断是否为Promise
    //     // if (ret.then) {
    //     //     return ret;
    //     // }

    //     return ret;
    //     // return new Promise((resolve) => {
    //     //     resolve(ret);
    //     // });
    // }

    destory() {
        this.exec.destory();
    }
}

export default SourceHandle;
