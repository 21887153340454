import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import AutoContext from "src/engine/decorator/AutoContext";
import PageMgrContext, { IPageMgrContext } from "src/engine/pages/ui/PageMgrContext";
import "./ImportPage.scss";
interface IImportPage extends IBaseUI {
    props: any;
    page: string;
}
interface IState {}

@UIManifest.declareGG(["ggValue"], "ImportPage", "ImportPage组件")
class ImportPage extends BaseUI<IImportPage, IState> {
    mContext: IPageMgrContext;

    @AutoContext(PageMgrContext, "mContext")
    render() {
        const { props = {}, page } = this.props;
        return (
            <div style={this.getStyle({ position: "relative" })} className="react-ipage">
                {!page ? null : this.mContext.renderPage({ props, ename: page, key: page as any }, 0, true)}
            </div>
        );
    }
}

export default ImportPage;
