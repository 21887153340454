import Script from "../Script";
import UIManifest from "src/engine/ui/UIManifest";
import { IInfo } from "src/component/props/PropsGGEditor/exec/ExecScript";

interface IAnchorScript {
    anchorId: string;
    delay?: number;
    // behavior: "auto"  | "instant" | "smooth",
    // block:    "start" | "end",
}

@UIManifest.declare("AnchorScript", "跳转页面锚点脚本", UIManifest.Type.Script)
export default class AnchorScript extends Script<IAnchorScript> {
    exec(info: IInfo) {
        const { anchorId, delay = 1 } = this.props;
        const { value } = info;
        const targetId = anchorId || value;
        if (delay > 300) {
            // 处理先切页面再跳锚点的情况
            setTimeout(() => {
                const anchorTarget = document.getElementById(targetId);
                if (!anchorTarget) return;
                try {
                    anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
                } catch (e) {
                    anchorTarget.scrollIntoView();
                }
            }, delay);
            return true;
        }
        const anchorTarget = document.getElementById(targetId);
        if (!anchorTarget) return true;
        try {
            setTimeout(() => anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" }), delay);
        } catch (e) {
            anchorTarget.scrollIntoView();
        }
        return true;
    }
}
