import * as React from "react";
import BaseUI, { IBaseUI } from "src/engine/ui/BaseUI";
import UIManifest from "src/engine/ui/UIManifest";
import Storage from "src/lib/utils/storage/Storage";

interface IJA extends IBaseUI {
    //地址
    href: string;
    //
    target: string;
    value?: any;
    disabled?: boolean;

    checkLogin?: boolean;
    checkLoginKey?: string;
    loginPage?: string;
}
interface IState {}

@UIManifest.declareGG(["ggValue", "dataValue"], "JA", "a标签组件", UIManifest.Type.JJ)
class JA extends BaseUI<IJA, IState> {
    onClick = () => {
        // console.log("[JA]:", 2222);
    };

    getHref() {
        const { checkLogin = false, checkLoginKey = "User_Id", loginPage, href, target = "_self" } = this.props;
        if (!checkLogin || !loginPage) return { href, target };
        //检测cookie中，是否包含某值
        if (new Storage().get(checkLoginKey, "1") === "1") {
            return { href: `#/${loginPage}`, target: "_self" };
        }
        return { href, target };
    }

    render() {
        const { uiItem, value, disabled } = this.props;
        let { href, target } = this.getHref() as any;
        if (!href) href = value;
        if (disabled) href = undefined;
        return (
            <a className="react-ja" href={href} target={target} onClick={this.onClick} style={this.getStyle()}>
                {this.renderChildren() || value || uiItem.info.cname}
            </a>
        );
    }
}

export default JA;
