import "src/components/PCPageMgr";import "src/components/Layout";import "src/components/View";import "src/component/jj/JImage";import "src/components/ButtonExt";import "src/components/Text";import "src/component/script/AddStyleTab";import "src/component/script/CheckScript";import "src/component/script/BaseProps";import "src/component/script/GGEdge";import "src/component/script/ClickScript";import "src/component/script/AggregationScript";import "src/component/script/HandleScript";import "src/component/script/CallbackScript";import "src/component/jj/JSwiper";import "src/component/source/SourceStore";import "src/component/script/AnchorScript";import "src/component/script/ImmediateScript";import "src/component/script/ClickGoUrl";import "src/component/jj/JA";import "src/components/ImportPage";import "src/components/JList";import "src/component/source/SourceNet";import "src/component/script/ArrayHandleScript";import "src/component/script/StringScript";
        const UILoadingManifest = (key: string) => {
            switch (key) {
                
                default:
                    return null;
            }
        };
        
        UILoadingManifest.PageManifest ={
            index:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","AddStyleTab","CheckScript","BaseProps","GGEdge","ClickGoUrl","JImage","JSwiper","AggregationScript","ArrayHandleScript","StringScript","ImportPage","SourceStore"],about:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","AddStyleTab","CheckScript","BaseProps","GGEdge","AnchorScript","HandleScript","ImmediateScript","AggregationScript","ImportPage"],contactus:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","AddStyleTab","CheckScript","BaseProps","GGEdge","ClickGoUrl","JA","StringScript","AggregationScript","ImportPage"],joinus:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","AddStyleTab","CheckScript","BaseProps","GGEdge","ClickGoUrl","AggregationScript","JA","ImportPage"],bottom:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","JImage","JList","BaseProps","AggregationScript","GGEdge","Text","SourceStore"],layout:["PCPage","View","Text","BaseProps","GGEdge","AggregationScript","JSwiper","ButtonExt","CallbackScript","ClickGoUrl","CheckScript","ArrayHandleScript","StringScript","ImportPage","ClickScript","AddStyleTab","SourceNet"],
        }

        export default UILoadingManifest;