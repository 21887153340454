import UIManifest from "src/engine/ui/UIManifest";
import Handle from "./Handle";
import BaseSource from "../BaseSource";

@UIManifest.declareGG(["ggValue"], "SourceStore", "SourceStore组件", UIManifest.Type.Data)
class SourceStore extends BaseSource {

    getExec() {
        return new Handle();
    }
}

export default SourceStore;
